<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('sms_sending_title')" :isFilter="false" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('sms_sending_title')" :isFilter="false" />
            </template>
            <b-row>
                <b-col md="4">
                    <b-card bg-variant="secondary" text-variant="white" :header="$t('sms_sending_with_number_info')" class="text-center" @click="callSmsModal">
                        <b-card-text>
                            <i class="ri-share-box-fill ri-7x"></i>
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col md="4">
                    <b-card bg-variant="dark" text-variant="white" :header="$t('sms_sending_with_student_search')" class="text-center" @click="$router.push({name: 'graduatesSendSmsSearch'})">
                        <b-card-text>
                            <i class="ri-share-circle-fill ri-7x"></i>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <CommonModal ref="sendSmsModal" size="lg">
                <template v-slot:CommonModalTitle>
                    {{ $t('sms_sending_with_number_info').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <sending @onSent="closeSmsModal"></sending>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

// Components
import CommonModal from "@/components/elements/CommonModal"

// Services

// Pages
import Sending from "./Sending"

// Other


export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        CommonModal,
        Sending
    },
    metaInfo() {
        return {
            title: this.$t('sms_sending_title')
        }
    },

    data() {
        return {

        }
    },
    created() {

    },

    methods: {
        callSmsModal(){
            this.$refs.sendSmsModal.$refs.commonModal.show()
        },
        closeSmsModal(){
            this.$refs.sendSmsModal.$refs.commonModal.hide()
        }
    }
}
</script>

